import type { RouteComponent, RouteRecordRaw } from 'vue-router'

export enum BaseRouteEnum {
  NotFound = 'NotFound',
}

type CMap = Record<BaseRouteEnum, RouteComponent | (() => Promise<RouteComponent>)>

export const DEFAULT_BASE_ROUTE_COMPONENTS: CMap = {
  NotFound: () => import('./NotFound.vue'),
}

export function createBaseRoutes(componentMap: Partial<CMap> = {}): RouteRecordRaw[] {
  const cMap = { ...DEFAULT_BASE_ROUTE_COMPONENTS, ...componentMap }
  return [
    {
      path: '/:pathMatch(.*)*',
      name: BaseRouteEnum.NotFound,
      component: cMap.NotFound,
      meta: {
        auth: false,
        title: '找不到页面',
      },
    },
  ]
}

export const baseRoutes = createBaseRoutes()
