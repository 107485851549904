import { type InjectionKey, provide, inject } from 'vue'

export type FlyhighSaaSConfig = {
  env: ImportMetaEnv
  logo?: string // logo 图片文件
}

const KEY = Symbol() as InjectionKey<FlyhighSaaSConfig>

/**
 * 提供环境配置
 */
export function useFlyhighSaaSProvide(config: FlyhighSaaSConfig) {
  provide(KEY, config)
}

/**
 * 注入环境配置
 */
export function useFlyhighSaaSInject() {
  const config = inject(KEY)
  if (!config) {
    throw new Error(
      '无法获取到注入的配置项，请确保正确使用了 FlyhighSaaS 组件，且其余所有组件位于 FlyhighSaaS 之内',
    )
  }
  return config
}
