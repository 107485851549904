import { strictFetch } from '@flyhigh-saas/auth'
import {
  type CUTimeDTO,
  type ID,
  type ListQueryParams,
  type ListResponseBody,
  type ResponseBody,
  SwitchStatus,
  b64Encode,
  downloadBlobFile,
} from '@flyhigh-saas/base'

export type UserListReqParams = ListQueryParams & {
  phonenumber?: string
  status?: string
  userName?: string
}

export type UserDTO = CUTimeDTO & {
  userId: ID
  userName: string
  nickName: string
  email: string
  phonenumber: string
  status: string
  deptId: ID
  sex: string
  postName: string
  roleIds: ID[]
  remark: string
  dept: {
    deptName: string
  }
  employeesNo: string
  admin: boolean
}

// 获取用户
export async function userListAPI(params: UserListReqParams) {
  const { data: resBody } = await strictFetch.get<ListResponseBody<UserDTO>>('/system/user/list', {
    params,
  })
  return resBody.data
}

export type AddOrEditUserDTO = Partial<Omit<UserDTO, 'dept' | 'createTime'>> & {
  password?: string
}

// 添加用户
export async function addUserAPI(req: AddOrEditUserDTO) {
  const { data: resBody } = await strictFetch.post<ResponseBody>('/system/user', req)
  return resBody.data
}

// 修改用户
export async function editUserAPI(req: AddOrEditUserDTO) {
  const { data: resBody } = await strictFetch.put<ResponseBody>('/system/user', req)
  return resBody.data
}

// 删除用户
export async function deleteUserAPI(ids: ID[]) {
  const { data: resBody } = await strictFetch.delete<ResponseBody>(`/system/user/${ids.join(',')}`)
  return resBody.data
}

export type ResetPwdDTO = {
  userId: ID
  password: string
}
// 重置密码
export async function resetPwdAPI(req: ResetPwdDTO) {
  const { data: resBody } = await strictFetch.put<ResponseBody>('/system/user/resetPwd', req)
  return resBody.data
}

export type UpdateUserStatusDTO = {
  userId: ID
  status: SwitchStatus
}

// 更新用户状态
export async function UpdateUserStatusAPI(req: UpdateUserStatusDTO) {
  const { data: resBody } = await strictFetch.put<ResponseBody>('/system/user/changeStatus', req)
  return resBody.data
}

// 导出用户数据
export async function ExportUserAPI() {
  const { data: resBody } = await strictFetch.post<Blob>(
    '/system/user/export',
    {},
    {
      responseType: 'blob',
    },
  )
  downloadBlobFile(resBody, `用户信息_${Date.now()}.xlsx`)
}

type UserDetailDTO = {
  data: UserDTO
  roleIds: string[]
}
// 获取用户详情
export async function userDetailAPI(userId: ID) {
  const { data: resBody } = await strictFetch.get<ResponseBody<UserDetailDTO>>(
    `/system/user/${userId}`,
  )
  return resBody.data
}

export type UserSelection = {
  userId: ID
  userName: string
  nickName: string
  deptName?: string
  deptId: string
}
// 获取用户下拉菜单(所有)
export async function getUserSelectionAPI() {
  const { data: resBody } = await strictFetch.get<ResponseBody<UserSelection[]>>(
    '/system/user/simpleUserlist',
  )
  return resBody.data
}

export type EditCurrentUserInfoDTO = Partial<
  Omit<UserDTO, 'userId' | 'createTime' | 'dept' | 'roleIds'>
>
// 修改当前用户资料
export async function editCurrentUserInfoAPI(data: EditCurrentUserInfoDTO) {
  const { data: resBody } = await strictFetch.post<ResponseBody>(
    '/system/user/profile/updateProfile',
    data,
  )
  return resBody.data
}

export type EditPasswordDTO = {
  oldPassword: string
  newPassword: string
}

// 修改当前用户密码
export async function editCurrentUserPasswordAPI(data: EditPasswordDTO) {
  const { data: resBody } = await strictFetch.put<ResponseBody>('/system/user/profile/updatePwd', {
    payload: getEditPasswordPayload(data),
  })
  return resBody.data
}
function getEditPasswordPayload(data: EditPasswordDTO) {
  const payload = `${b64Encode(data.oldPassword, true)}.${b64Encode(data.newPassword, true)}`
  return payload.split('').reverse().join('')
}
