/*
 * @Author Cphayim
 * @Description 严格的请求器，用于请求自己的服务端（能够返回固定格式的）
 */
import {
  type FetchinConfig,
  createBearerAuthRequestInterceptor,
  createFetchin,
  verifyResponseBodyInterceptor,
} from '@flyhigh-saas/base'

import { useAuthStore } from '../stores/auth.store'
import { rawToFetchinResponseTransformer } from './raw-to-fetchin'

if (!import.meta.env.VITE_APP_API_URL) {
  console.warn('没有找到 VITE_APP_API_URL 环境变量')
}

const DEFAULT_CONFIG: FetchinConfig = {
  baseURL: import.meta.env.VITE_APP_API_URL,
  requestInterceptors: [
    createBearerAuthRequestInterceptor({
      getBearerToken: () => useAuthStore().token as string,
    }),
  ],

  responseTransformers: [
    // 将后端返回的 raw 类型转为符合 fetchin 规范的类型
    rawToFetchinResponseTransformer,
  ],
  responseInterceptors: [
    // 验证响应体是否符合规范
    verifyResponseBodyInterceptor,
    // 匹配响应体的 code
    // matchResponseBodyCodeInterceptor,
  ],
  timeout: 30000,
  validateStatus: () => true,
}

/**
 * 严格的请求器，用于请求自己的服务端
 *
 * 如需请求第三方服务端，应该使用 base 包提供的 `unstrictFetch`
 */
export const strictFetch = createFetchin(DEFAULT_CONFIG)
