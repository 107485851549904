import {
  type ListResponseBody,
  type RawListResponseBody,
  type RawResponseBody,
  createResponseTransformer,
} from '@flyhigh-saas/base'

// 将后端返回的 raw 类型转为符合 fetchin 规范的类型
// RawListResponseBody -> ListResponseBody
// RawResponseBody -> ResponseBody
export const rawToFetchinResponseTransformer = createResponseTransformer((raw, _, config) => {
  // 非 json 返回，比如 blob
  if (config.responseType !== 'json') return raw

  // 非有效 Raw 类型
  // 这里需要直接抛出异常，因为拦截器会校验所有 json 返回是否为 Fetchin 类型
  if (
    !raw ||
    typeof raw !== 'object' ||
    !raw.hasOwnProperty('code') ||
    !raw.hasOwnProperty('msg')
  ) {
    console.error(raw)
    throw new Error(
      `[responseTransformer]: 转换失败，响应体不是 RawResponseBody 或 RawListResponseBody 类型`,
    )
  }

  if (raw.hasOwnProperty('rows')) {
    // RawListResponseBody -> ListResponseBody
    const r = raw as RawListResponseBody
    return {
      code: r.code,
      message: r.msg,
      data: {
        rows: r.rows,
        total: r.total,
      },
    } as ListResponseBody
  } else {
    // RawResponseBody -> ResponseBody
    const r = raw as RawResponseBody
    return {
      code: r.code,
      message: r.msg,
      data: r.data,
    }
  }
})
