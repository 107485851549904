<!--
 * @Author: Cphayim
 * @Date: 2023-07-04 11:37:21
 * @Description: 配置组件，通过 provide() 提供配置数据给所有子组件，子组件 inject() 使用
 * !!注意：该组件必须在应用级根组件中（位于所有 saas 模块组件之外）
-->

<script setup lang="ts">
import { type FlyhighSaaSConfig, useFlyhighSaaSProvide } from '../hooks'

defineOptions({ name: 'FlyhighSaaS' })

type Props = Omit<FlyhighSaaSConfig, 'env'>
const props = defineProps<Props>()

useFlyhighSaaSProvide({ env: import.meta.env, ...props })
</script>

<template>
  <slot />
</template>

<style>
:root {
  --color-light-blue: #1890ff;
  --color-blue: #284ca1;
  --color-dark-blue: #214192;
  --color-green: #42c08c;
  --color-purple: #7e6bd1;
  --color-yellow: #fcc14f;
  --color-orange: #fc9b4f;
  --color-red: #e8503c;
  --color-light-grey: #adb5ca;
  --color-gray: #7b8cb9;
}
</style>
