import { SaaSPlatform } from '@flyhigh-saas/base'

export const contractRoutes = [
  {
    path: '/contract/expense',
    name: 'ExpenseContractPage',
    component: () => import('./expense/ExpenseContractPage.vue'),
    meta: {
      auth: true,
      title: '支出合同',
      keepAlive: true,
      menu: true,
      platform: SaaSPlatform.合同管理,
    },
  },
  {
    path: '/contract/change',
    name: 'ChangeContractPage',
    component: () => import('./change/ChangeContractPage.vue'),
    meta: {
      auth: true,
      title: '变更管理',
      keepAlive: true,
      menu: true,
      platform: SaaSPlatform.合同管理,
    },
  },
  {
    path: '/contract/ContractLedgerPage',
    name: 'ContractLedgerPage',
    component: () => import('./contractLedger/ContractLedgerPage.vue'),
    meta: {
      auth: true,
      title: '合同台帐',
      keepAlive: true,
      menu: true,
      platform: SaaSPlatform.合同管理,
    },
  },
  {
    path: '/contract/ContractAuditPage',
    name: 'ContractAuditPage',
    component: () => import('./contractAudit/ContractAuditPage.vue'),
    meta: {
      auth: true,
      title: '合同审批',
      keepAlive: true,
      menu: true,
      platform: SaaSPlatform.合同管理,
    },
  },
  {
    path: '/contract/ContractSettlementPage',
    name: 'ContractSettlementPage',
    component: () => import('./contractSettlement/ContractSettlementPage.vue'),
    meta: {
      auth: true,
      title: '决算管理',
      keepAlive: true,
      menu: true,
      platform: SaaSPlatform.合同管理,
    },
  },
  {
    path: '/contract/BatchManagementPage',
    name: 'BatchManagementPage',
    component: () => import('./batchManagement/BatchManagementPage.vue'),
    meta: {
      auth: true,
      title: '批次管理',
      keepAlive: true,
      menu: true,
      platform: SaaSPlatform.合同管理,
    },
  },
  {
    path: '/contract/RequestManagementPage',
    name: 'RequestManagementPage',
    component: () => import('./requestManagement/RequestManagementPage.vue'),
    meta: {
      auth: true,
      title: '请款管理',
      keepAlive: true,
      menu: true,
      platform: SaaSPlatform.合同管理,
    },
  },
  {
    path: '/contract/RequestAuditPage',
    name: 'RequestAuditPage',
    component: () => import('./requestAudit/RequestAuditPage.vue'),
    meta: {
      auth: true,
      title: '请款审批',
      keepAlive: true,
      menu: true,
      platform: SaaSPlatform.合同管理,
    },
  },
]
