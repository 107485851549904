import { useEmitter, usePagination } from '@cphayim-enc/vue-element-plus'
import { defineStore } from 'pinia'
import { ref } from 'vue'

import type { ID } from '@flyhigh-saas/base'
import { type ProjectDTO, type ProjectQueryParams, projectListAPI } from '@flyhigh-saas/project'

type Events = {
  refresh: boolean | undefined // 刷新 default: false  true:清除列表排序状态
  create: undefined // 新增
  update: ID // 编辑
  detail: ID // 详情
  map: string // 地图
}

export const useStore = defineStore('Project', () => {
  const emitter = useEmitter<Events>()

  // 搜索过滤
  const searchCondition = ref<ProjectQueryParams>({})

  // 分页数据
  const { pagination, resetPagination, resetPaginationKeepPageSize } = usePagination({
    initPageSize: 12,
  })

  const listData = ref<ProjectDTO[]>([])

  async function fetchList() {
    const { rows, total } = await projectListAPI({
      ...searchCondition.value,
      pageSize: pagination.value.pageSize,
      pageNum: pagination.value.pageNum,
    })
    pagination.value.total = total
    listData.value = rows
  }

  // 翻页
  async function resetPaginationAndFetch() {
    resetPaginationKeepPageSize()
    await fetchList()
  }

  return {
    emitter,
    searchCondition,
    listData,
    fetchList,
    pagination,
    resetPagination,
    resetPaginationKeepPageSize,
    resetPaginationAndFetch,
  }
})
