import {
  type MatchResponseBodyCodeRules,
  ResponseCode,
  createMatchResponseBodyCodeInterceptor,
} from '@flyhigh-saas/base'

import { useAuthStore } from '../stores'

// 匹配规则，对应 code 的函数会被执行
// 返回 false 将抛出异常
const DEFAULT_MATCH_RULES: MatchResponseBodyCodeRules = {
  [ResponseCode.OK]: () => true,
  [ResponseCode.UNAUTHORIZED]: () => {
    useAuthStore().logout()
    return false
  },
  [ResponseCode.SERVER_ERROR]: () => {
    return false
  },
}

// FetchinResponseBody.code 匹配器
export const matchResponseBodyCodeInterceptor = createMatchResponseBodyCodeInterceptor({
  matchRules: DEFAULT_MATCH_RULES,
})

export function createPlatformMatchResponseBodyCodeInterceptor(
  platformMatchRules: MatchResponseBodyCodeRules,
) {
  const matchRules = {
    ...DEFAULT_MATCH_RULES,
    ...platformMatchRules,
  }
  return createMatchResponseBodyCodeInterceptor({
    matchRules,
  })
}
