<script setup lang="ts">
import { useEventLock } from '@cphayim-enc/vue-element-plus'
import { Close, HomeFilled } from '@element-plus/icons-vue'
import { computed } from 'vue'
import { useRouter } from 'vue-router'

import type { KeepAliveRouteInfo } from '../hooks'
import type { UseRouterStore } from '../stores'

type Props = {
  /**
   * 无法确定项目中使用到的 routes，因而需要在外部创建 useRouterStore 传递进来
   */
  useRouterStore: UseRouterStore
}
const props = withDefaults(defineProps<Props>(), {})

const HOME_PATH = '/dashboard'
const store = props.useRouterStore()

// 首页永久置顶，需要排除 keepAlivedInfos 中的首页
const tabs = computed(() => store.keepAlivedInfos.filter((info) => info.routePath !== HOME_PATH))

const router = useRouter()
const handleNavigateTo = useEventLock((path: string) => {
  router.push(path)
})
const handleRemoveTab = useEventLock((tab: KeepAliveRouteInfo, index: number) => {
  // 关闭当前页时，跳转到下一个页，如果没有下一个页，则跳转到上一个页，如果没有上一个页，则跳转到首页
  const nextTab = tabs.value[index + 1] ?? tabs.value[index - 1] ?? { routePath: HOME_PATH }
  router.push(nextTab.routePath)

  store.rmKeepAlive(tab)
})
</script>

<template>
  <div class="page-tab">
    <!-- 首页固定 -->
    <div
      @click="handleNavigateTo(HOME_PATH)"
      class="tab-item home"
      :class="{ active: $route.path === HOME_PATH }"
    >
      <el-icon class="home-icon"><HomeFilled /></el-icon>
      <span class="truncate">首页</span>
    </div>

    <!-- 其余项 -->
    <template v-for="(tab, index) in tabs" :key="tab.routePath">
      <div
        @click="handleNavigateTo(tab.routePath)"
        class="tab-item"
        :class="{ active: $route.path === tab.routePath }"
        :title="tab.title"
      >
        <span class="truncate">{{ tab.title }}</span>
        <el-icon @click.stop="handleRemoveTab(tab, index)" class="close-icon">
          <Close />
        </el-icon>
      </div>
    </template>
  </div>
</template>

<style scoped>
.page-tab {
  @apply overflow-hidden flex items-end h-[52px] px-[10px] text-[14px] bg-white shadow-sm;
}
.tab-item {
  @apply overflow-hidden relative z-10;
  @apply flex justify-center items-center h-[42px] px-[10px] cursor-pointer;

  &.home {
    @apply w-[100px];
  }

  &.active {
    @apply bg-[#f0f2f5] border border-solid border-gray-300 border-b-0 rounded-t-[10px] pointer-events-none;
    &::after {
      @apply absolute top-full left-0;
      @apply w-full h-[2px] bg-[#f0f2f5];
      content: '';
    }
  }

  .home-icon {
    @apply mr-[6px] text-[20px] text-primary;
  }
  .close-icon {
    @apply ml-[10px] rounded-full text-[16px] text-gray-500 cursor-pointer pointer-events-auto hover:bg-gray-400 hover:text-white;
  }
}
</style>
