import type { RouteComponent, RouteRecordRaw } from 'vue-router'

import { AUTH_ROUTE, SaaSPlatform } from '@flyhigh-saas/base'

export enum AuthRouteEnum {
  Auth = 'Auth',
}

type CMap = Record<AuthRouteEnum, RouteComponent | (() => Promise<RouteComponent>)>

export const DEFAULT_ROUTE_COMPONENTS: CMap = {
  Auth: () => import('./AuthPage.vue'),
}

export function createAuthRoutes(componentMap: Partial<CMap> = {}): RouteRecordRaw[] {
  const cMap = { ...DEFAULT_ROUTE_COMPONENTS, ...componentMap }
  return [
    {
      // 登录路由路径使用该常量定义
      path: AUTH_ROUTE,
      name: AuthRouteEnum.Auth,
      component: cMap.Auth,
      meta: {
        platform: SaaSPlatform.认证授权,
        auth: false,
        title: '登录',
      },
    },
  ]
}

export const authRoutes = createAuthRoutes()
