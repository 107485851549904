/*
 * @Author Cphayim
 * @Description 非严格的请求器，用于请求第三方服务端
 */
import { type FetchinConfig, createFetchin } from '@ombro/fetchin'

const DEFAULT_CONFIG: FetchinConfig = {
  timeout: 30000,
  validateStatus: () => true,
}

/**
 * 非严格的请求器，用于请求第三方服务端
 *
 * 如需请求己方服务端，需要携带 token 或验证响应结构的，应该使用 auth 包提供的 `strictFetch`
 */
export const unstrictFetch = createFetchin(DEFAULT_CONFIG)
