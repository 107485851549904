<script setup lang="ts">
import { ElConfigProvider } from 'element-plus'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import zhCn from 'element-plus/dist/locale/zh-cn.min.mjs'

import { useTraceLoginStatus } from '@flyhigh-saas/auth'
import { FlyhighSaaS } from '@flyhigh-saas/base'

import LOGO from '@/assets/images/logo.jpg'

// 跟踪登录状态进行页面切换/获取用户信息
useTraceLoginStatus()

const ElMessageConfig = reactive({ max: 3 })
</script>

<template>
  <FlyhighSaaS :logo="LOGO">
    <el-config-provider :message="ElMessageConfig" :locale="zhCn">
      <router-view />
    </el-config-provider>
  </FlyhighSaaS>
</template>

<style scoped></style>
