import { createRouter, createWebHistory } from 'vue-router'

import { authGuard } from '@flyhigh-saas/auth'
import { titleGuard } from '@flyhigh-saas/base'
import {
  createKeepAliveGuard,
  createRouteLoadGuard,
  createRouterStore,
} from '@flyhigh-saas/pc-layout'

import { createGlobalRoutes, dynamicRoutes } from './routes'

const useRouterStore = createRouterStore({ routes: dynamicRoutes })

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: createGlobalRoutes(useRouterStore),
})

router.beforeEach(authGuard)
router.beforeEach(createRouteLoadGuard(useRouterStore))

router.afterEach(titleGuard)
router.afterEach(createKeepAliveGuard(useRouterStore))

export default router
