import './iconfont.js'

export const ICONS_2 = {
  菜单_首页: 'home',
  菜单_资产管理: 'build',
  菜单_营销模块: 'marketing',
  菜单_物业模块: 'tenements',
  菜单_系统管理: 'setting',
  折叠_展开: 'expand',
  折叠_收起: 'shrink',
  通知: 'notice',
  退出: 'exit',
  列表_新增: 'add',
  列表_编辑: 'edit',
  列表_查看详情: 'detail',
  列表_删除: 'delete',
  列表_取消: 'cancel',
  列表_已付款: 'payed',
  列表_待付款: 'pending-pay',
  列表_审核: 'audit-2',
  列表_待审核: 'pending-review',
  列表_上传: 'upload-2',
  列表_数据权限: 'permission',
  列表_用户角色: 'user-permission',
  列表_重置密码: 'reset-password',
  列表_维修: 'maintain',
  列表_隐患: 'trouble',
  列表_费用录入: 'cost',
  列表_账单: 'bill',
  列表_拒绝: 'reject',
  提示: 'tip',
  下载: 'download',
  上传: 'upload',
  导出: 'export',
  推送: 'push',
  撤销: 'revocation',
  时间: 'time',
  时间_实心: 'time-2',
  通过: 'pass',
  警告: 'warning',
  创建: 'create',
  创建_圆形: 'create-2',
}
