export const materialRoutes = [
  {
    path: '/material/manage',
    name: 'MaterialManagePage',
    component: () => import('./materialManage/MaterialManagePage.vue'),
    meta: {
      platform: '物资管理',
      auth: true,
      title: '物资管理',
      keepAlive: true,
      menu: true,
    },
  },
  {
    path: '/material/type',
    name: 'MaterialTypePage',
    component: () => import('./materialType/MaterialTypePage.vue'),
    meta: {
      platform: '物资管理',
      auth: true,
      title: '物资类别管理',
      keepAlive: true,
      menu: true,
    },
  },
]
