import { ElMessage } from 'element-plus'

import { createPlatformMatchResponseBodyCodeInterceptor, strictFetch } from '@flyhigh-saas/auth'
import { type MatchResponseBodyCodeRules, ResponseCode } from '@flyhigh-saas/base'

// 对应平台的特殊处理
const matchRules: MatchResponseBodyCodeRules = {
  [ResponseCode.SERVER_ERROR]: (res) => {
    ElMessage.error({ message: res.data.message })
    return false
  },
}

export const addPlatformInterceptorToFetchin = () => {
  strictFetch.addResponseInterceptor(createPlatformMatchResponseBodyCodeInterceptor(matchRules))
}
