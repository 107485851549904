/**
 * SaaS 平台
 */
export const enum SaaSPlatform {
  认证授权 = 'auth',
  系统管理 = 'system',
  消息中心 = 'message',
  发文管理 = 'sendDocument',
  工单管理 = 'workorder',
  项目管理 = 'project',
  合同管理 = 'contract',
  建设管理 = 'construction',
  运营管理 = 'assets',
  安全检查 = 'security',
  工单系统 = 'workOrder',
}
