import { SaaSPlatform } from '@flyhigh-saas/base'

export const workorderRoutes = [
  {
    path: '/workorder',
    name: 'WorkOrderHomePage',
    component: () => import('./home/index.vue'),
    meta: {
      title: '工单首页',
      auth: true,
      keepAlive: true,
      menu: true,
      platform: SaaSPlatform.工单管理,
    },
  },
  {
    path: '/workorder/orders',
    name: 'Orders',
    component: () => import('./order/Orders.vue'),
    meta: {
      title: '工单列表',
      auth: true,
      keepAlive: true,
      menu: true,
      platform: SaaSPlatform.工单管理,
    },
  },
  {
    path: '/workorder/orders/:id(\\d+)',
    name: 'OrderDetail',
    component: () => import('./order/OrderDetail.vue'),
    meta: {
      title: '工单详情',
      auth: true,
      keepAlive: true,
      menu: true,
      platform: SaaSPlatform.工单管理,
    },
  },
  {
    path: '/workorder/orders/create',
    name: 'CreateOrder',
    component: () => import('./order/CreateOrder.vue'),
    meta: {
      title: '新建工单',
      auth: true,
      keepAlive: true,
      menu: true,
      platform: SaaSPlatform.工单管理,
    },
  },

  {
    path: '/workorder/templates',
    name: 'Templates',
    component: () => import('./template/Templates.vue'),
    meta: {
      title: '模板列表',
      auth: true,
      keepAlive: true,
      menu: true,
      platform: SaaSPlatform.工单管理,
    },
  },
  {
    path: '/workorder/templates/:id(\\d+)',
    name: 'TemplateDetail',
    component: () => import('./template/TemplateDetail.vue'),
    meta: {
      title: '模板详情',
      auth: true,
      keepAlive: true,
      menu: true,
      platform: SaaSPlatform.工单管理,
    },
  },
  {
    path: '/workorder/templates/create',
    name: 'CreateTemplate',
    component: () => import('./template/TemplateCreateOrEdit.vue'),
    meta: {
      title: '新增模板',
      auth: true,
      keepAlive: true,
      menu: true,
      platform: SaaSPlatform.工单管理,
    },
  },
  {
    path: '/workorder/templates/edit/:id(\\d+)',
    name: 'EditTemplate',
    component: () => import('./template/TemplateCreateOrEdit.vue'),
    meta: {
      title: '编辑模板',
      auth: true,
      keepAlive: true,
      menu: true,
      platform: SaaSPlatform.工单管理,
    },
  },
  {
    path: '/workorder/formTemplates',
    name: 'FormTemplates',
    component: () => import('./template/FormTemplates.vue'),
    meta: {
      title: '模板列表', // 这里是表单模板列表，title 依然叫 模板列表
      auth: true,
      keepAlive: true,
      menu: true,
      platform: SaaSPlatform.工单管理,
    },
  },
  {
    path: '/workorder/formTemplates/:id(\\d+)',
    name: 'FormTemplateDetail',
    component: () => import('./template/FormTemplateDetail.vue'),
    meta: {
      title: '预设动态表单详情',
      auth: true,
      keepAlive: true,
      menu: true,
      platform: SaaSPlatform.工单管理,
    },
  },
  {
    path: '/workorder/formTemplates/create',
    name: 'CreateFormTemplate',
    component: () => import('./template/FormTemplateCreateOrEdit.vue'),
    meta: {
      title: '新增预设动态表单',
      auth: true,
      keepAlive: true,
      menu: true,
      platform: SaaSPlatform.工单管理,
    },
  },
  {
    path: '/workorder/formTemplates/edit/:id(\\d+)',
    name: 'EditFormTemplate',
    component: () => import('./template/FormTemplateCreateOrEdit.vue'),
    meta: {
      title: '编辑预设动态表单',
      auth: true,
      keepAlive: true,
      menu: true,
      platform: SaaSPlatform.工单管理,
    },
  },
]
