import '@cphayim-enc/extension-vue-form-editor/style'
import '@cphayim-enc/vue-element-plus/style'

import { addPlatformInterceptorToFetchin } from '@flyhigh-saas/pc-auth'

import App from './App.vue'
import './assets/css'
import router from './router'
import store from './stores'

addPlatformInterceptorToFetchin()

createApp(App).use(router).use(store).mount('#app')
