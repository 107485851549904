import type { RouteComponent, RouteRecordRaw } from 'vue-router'

import { SaaSPlatform } from '@flyhigh-saas/base'

export enum ProjectArchiveRouteEnum {
  ProjectArchivePage = 'ProjectArchivePage',
}

type CMap = Record<ProjectArchiveRouteEnum, RouteComponent | (() => Promise<RouteComponent>)>

const DEFAULT_ROUTE_COMPONENTS: CMap = {
  ProjectArchivePage: () => import('./project-archive/ProjectArchivePage.vue'),
}

export function createProjectArchiveRoutes(componentMap: Partial<CMap> = {}): RouteRecordRaw[] {
  const cMap = { ...DEFAULT_ROUTE_COMPONENTS, ...componentMap }
  return [
    {
      path: '/project/archive',
      name: ProjectArchiveRouteEnum.ProjectArchivePage,
      component: cMap.ProjectArchivePage,
      meta: {
        platform: SaaSPlatform.项目管理,
        auth: true,
        title: '项目主档',
        keepAlive: true,
        menu: true,
      },
    },
  ]
}

export const projectArchiveRoutes = createProjectArchiveRoutes()
