<script setup lang="ts">
import * as elIconsMap from '@element-plus/icons-vue'

export type ElIcons = keyof typeof elIconsMap

const props = withDefaults(
  defineProps<{
    /**
     * 要使用的element-ui图标名称
     */
    elementIcon?: ElIcons
    /**
     * 要使用的图标名称
     */
    icon?: string
    /**
     * 图标文字提示，不传递则禁用 tooltip
     */
    label?: string
    /**
     * 图标大小
     * @default 20
     */
    size?: number
    /**
     * 图标颜色
     */
    color?: string
    /**
     * 是否显示可点击态鼠标指针
     * @default true
     */
    pointer?: boolean
    /**
     * 是否在右侧添加 margin，只在非最后一个元素中有效
     */
    mr?: boolean
  }>(),
  {
    size: 20,
    pointer: true,
    mr: false,
  },
)

const emit = defineEmits<{ (e: 'click'): void }>()
</script>

<template>
  <el-tooltip :content="props.label" :disabled="!props.label" placement="bottom" effect="light">
    <el-icon
      :size="props.size"
      :color="props.color"
      @click="emit('click')"
      :class="['icon-wrap', { pointer: props.pointer, mr: props.mr }]"
    >
      <svg v-if="props.icon" class="icon" aria-hidden="true">
        <use :xlink:href="`#icon-${props.icon}`" />
      </svg>
      <component v-else-if="props.elementIcon" :is="elIconsMap[props.elementIcon]" />
    </el-icon>
  </el-tooltip>
</template>

<style scoped>
.icon-wrap {
  &.mr {
    @apply mr-[15px] last:mr-0;
  }

  &.pointer {
    @apply cursor-pointer clickable;
  }
}

.icon {
  @apply text-current;
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
</style>
