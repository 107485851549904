<!--
 * @Author: Cphayim
 * @Date: 2022-12-14 00:18:26
 * @Description: 左侧主侧边栏，支持展开/折叠
-->
<script setup lang="ts">
import { EncForm, useEventLock, useForm } from '@cphayim-enc/vue-element-plus'
import { useVModel } from '@vueuse/core'
import { ElMessage } from 'element-plus'
import { storeToRefs } from 'pinia'
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'

import { useAuthStore } from '@flyhigh-saas/auth'
import { useFlyhighSaaSInject, useLogo } from '@flyhigh-saas/base'
import { Dialog, IconV } from '@flyhigh-saas/pc-base'
import {
  type EditCurrentUserInfoDTO,
  editCurrentUserInfoAPI,
  editCurrentUserPasswordAPI,
} from '@flyhigh-saas/system'

import defaultAvatar from '../assets/images/default-avatar.jpg'
import type { UseRouterStore } from '../stores'

type Props = {
  collapse?: boolean // 是否折叠
  /**
   * 无法确定项目中使用到的 routes，因而需要在外部创建 useRouterStore 传递进来
   */
  useRouterStore: UseRouterStore
}
const props = withDefaults(defineProps<Props>(), {
  collapse: false,
})

const logo = useLogo()

const config = useFlyhighSaaSInject()
const collapse = useVModel(props, 'collapse')

const route = useRoute()
// 路由菜单
const { menus } = storeToRefs(props.useRouterStore())

const authStore = useAuthStore()
const { user } = storeToRefs(authStore)
const role = computed(() => user.value?.roles[0]) // 取第一个角色
const dept = computed(() => user.value?.dept)

// ---------------------------------------------------------------------------

const showFloatUserInfo = ref(false)

const showEditUserInfoDialog = ref(false)
const getEditUserInfoInitFormData = (): EditCurrentUserInfoDTO => ({
  ...user.value,
})
const {
  formData: editUserInfoFormData,
  formItems: editUserInfoFormItems,
  formRef: editUserInfoFormRef,
} = useForm(getEditUserInfoInitFormData(), [
  {
    type: 'input',
    label: '用户姓名',
    name: 'nickName',
    rules: [{ required: true, message: '请输入用户姓名' }],
  },
  {
    type: 'radio',
    label: '用户性别',
    name: 'sex',
    radioOptions: [
      { label: '男', value: '1' },
      { label: '女', value: '0' },
    ],
  },
  {
    type: 'input',
    label: '联系电话',
    name: 'phonenumber',
  },
  {
    type: 'input',
    label: '电子邮箱',
    name: 'email',
  },
])
const handleEditUserInfoSubmit = useEventLock(async () => {
  await editUserInfoFormRef.value?.validate()
  await editCurrentUserInfoAPI(editUserInfoFormData.value)

  ElMessage.success('修改成功')
  showEditUserInfoDialog.value = false
  await authStore.fetchUserProfile()
})

const showEditPasswordDialog = ref(false)
const getEditPasswordFormData = (): any => ({})
const {
  formData: editPasswordFormData,
  formItems: editPasswordFormItems,
  formRef: editPasswordFormRef,
} = useForm(getEditPasswordFormData(), [
  {
    type: 'input',
    label: '旧密码',
    name: 'oldPassword',
    inputType: 'password',
    rules: [{ required: true, message: '请输入旧密码' }],
  },
  {
    type: 'input',
    label: '新密码',
    name: 'newPassword',
    inputType: 'password',
    rules: [{ required: true, message: '请输入新密码' }],
  },
  {
    type: 'input',
    label: '确认密码',
    name: 'confirmPassword',
    inputType: 'password',
    rules: [{ required: true, message: '请输入确认密码' }],
  },
])
const handleEditPasswordSubmit = useEventLock(async () => {
  await editUserInfoFormRef.value?.validate()
  if (editPasswordFormData.value.newPassword !== editPasswordFormData.value.confirmPassword) {
    ElMessage.error('两次输入的密码不一致')
    return
  }
  await editCurrentUserPasswordAPI(editPasswordFormData.value)
  ElMessage.success('密码修改成功，请重新登录')
  showEditPasswordDialog.value = false
  await authStore.logout()
})

const handleOpenWindow = useEventLock((url: string) => {
  // 跳转新窗口打开
  window.open(url, '_blank')
})
</script>

<template>
  <div class="main-side-bar" :class="{ 'main-side-bar__small': props.collapse }">
    <div class="header-bar clickable" @click="$router.push('/')">
      <div class="logo" :style="{ backgroundImage: `url(${logo})` }" />
      <div v-if="!props.collapse" class="whitespace-nowrap">{{ config.env.VITE_APP_TITLE }}</div>
    </div>

    <div class="flex flex-col flex-1 overflow-auto bg-white shadow">
      <!-- 用户基本信息 -->
      <div v-if="user" class="my-[25px]">
        <!-- 展开模式 -->
        <div v-if="!props.collapse" class="flex flex-col items-center">
          <div @click="showFloatUserInfo = true" class="relative cursor-pointer hover:scale-110">
            <img
              :src="user.avatar || defaultAvatar"
              class="w-[80px] h-[80px] border-primary border-2 border-solid rounded-full"
            />
            <div class="absolute bottom-0 flex justify-center w-full">
              <span class="px-[6px] rounded-full text-[12px] text-white bg-green-500">
                {{ role?.roleName }}
              </span>
            </div>
          </div>
          <div
            class="flex items-center mt-[10px] mb-[5px] text-[16px] text-primary font-bold clickable"
          >
            <span class="translate-y-[2px]">{{ user.nickName }}</span>
            <IconV element-icon="CaretBottom" :size="16" />
          </div>
          <div class="text-[12px] text-gray-500 leading-[1]">{{ dept?.deptName }}</div>
        </div>
        <!-- 折叠模式 -->
        <div v-else class="relative flex justify-center cursor-pointer hover:scale-110">
          <img
            :src="user.avatar || defaultAvatar"
            @click="showFloatUserInfo = true"
            class="w-[45px] h-[45px] border-primary border-2 border-solid rounded-full"
          />
        </div>
      </div>

      <!-- 路由菜单 -->
      <div class="menus flex-1">
        <el-menu :default-active="route.path" :collapse="props.collapse" router unique-opened>
          <template v-for="menu in menus" :key="menu.fullPath">
            <!-- 叶子节点 -->
            <el-menu-item v-if="!menu.children" :index="menu.fullPath">
              <IconV v-if="menu.icon" :icon="menu.icon" />
              <span v-if="!collapse">{{ menu.title }}</span>
            </el-menu-item>

            <!-- 非叶子节点 -->
            <el-sub-menu v-else :index="menu.title">
              <template #title>
                <IconV v-if="menu.icon" :icon="menu.icon" />
                <span>{{ menu.title }}</span>
              </template>

              <el-menu-item
                v-for="subMenu in menu.children"
                :key="subMenu.fullPath"
                :index="subMenu.fullPath"
              >
                <IconV v-if="subMenu.icon" :icon="subMenu.icon" />
                <span v-else class="inline-block w-[10px]"></span>
                {{ subMenu.title }}
              </el-menu-item>
            </el-sub-menu>
          </template>
        </el-menu>
      </div>

      <!-- 额外功能 -->
      <div class="op px-[10px]">
        <div class="op-btn" @click="handleOpenWindow('/workorder')">
          <IconV element-icon="Tickets" :size="16" color="#fff" />
          <span v-if="!props.collapse" class="ml-[10px]">工单管理</span>
        </div>
      </div>
    </div>
  </div>

  <!-- 用户信息浮层 -->
  <Teleport to="body">
    <div
      v-if="showFloatUserInfo && user"
      class="float-user-info"
      :class="{ small: collapse }"
      @mouseleave="showFloatUserInfo = false"
    >
      <div
        class="absolute right-[10px] top-[10px] text-gray-500"
        @click="showFloatUserInfo = false"
      >
        <IconV element-icon="Close" />
      </div>
      <div class="flex items-center">
        <img
          :src="user.avatar || defaultAvatar"
          class="w-[80px] h-[80px] mr-[20px] border-primary border-2 border-solid rounded-full"
        />
        <div>
          <div class="text-[18px]">{{ user.nickName }}</div>
          <div class="text-[12px]">
            {{ dept?.deptName }}{{ user.postName ? `/${user.postName}` : '' }}
          </div>
          <div class="flex items-center text-[12px] mt-[6px]">
            <div class="flex items-center">
              <IconV
                :size="12"
                element-icon="UserFilled"
                :color="user.sex === '1' ? '#3D7FFF' : '#F54257'"
                :pointer="false"
              />
              <span class="ml-[4px]">{{ user.sex === '1' ? '男' : '女' }}</span>
            </div>
            <div class="w-[1px] h-[12px] mx-[10px] bg-gray-300" />
            <div class="flex items-center">
              <IconV :size="12" element-icon="Iphone" color="#3D7FFF" :pointer="false" />
              <span class="ml-[4px]">{{ user.phonenumber }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-around items-center mt-[10px] leading-[40px] text-[14px]">
        <div
          @click="showEditUserInfoDialog = true"
          class="text-gray-500 hover:text-primary active:text-primary clickable"
        >
          修改信息
        </div>
        <div class="w-[1px] h-[20px] bg-gray-300" />
        <div
          @click="showEditPasswordDialog = true"
          class="text-gray-500 hover:text-primary active:text-primary clickable"
        >
          修改密码
        </div>
      </div>
    </div>
  </Teleport>

  <Dialog
    v-model="showEditUserInfoDialog"
    title="修改用户信息"
    width="660px"
    buttons
    @confirm="handleEditUserInfoSubmit"
  >
    <EncForm
      v-model:data="editUserInfoFormData"
      :items="editUserInfoFormItems"
      ref="editUserInfoFormRef"
      :label-width="100"
    />
  </Dialog>

  <Dialog
    v-model="showEditPasswordDialog"
    title="修改密码"
    width="660px"
    buttons
    @confirm="handleEditPasswordSubmit"
  >
    <EncForm
      v-model:data="editPasswordFormData"
      :items="editPasswordFormItems"
      ref="editPasswordFormRef"
      :label-width="100"
    />
  </Dialog>
</template>

<style scoped>
.main-side-bar {
  @apply relative z-10;
  @apply flex-shrink-0 flex flex-col w-[200px] h-screen;
  transition: width 0.3s;
  --el-menu-item-height: 48px;
  --el-menu-bg-color: transparent;
  * {
    transition: all 0.15s;
  }
  &.main-side-bar__small {
    @apply w-[75px];
  }
}

.main-side-bar__small {
  .header-bar .logo {
    @apply mx-[0];
  }
  .menus {
    @apply pl-[10px];
  }
}

.header-bar {
  @apply overflow-hidden flex-shrink-0 flex justify-center items-center h-[var(--layout-header-bar-height)];
  @apply bg-primary-dark text-white text-[18px];
  height: 48px;
  height: var(--layout-header-bar-height);
  .logo {
    @apply w-[25px] h-[25px] mr-[8px] rounded-[6px] bg-contain bg-no-repeat bg-center;
  }
}

.menus {
  @apply my-[30px];
}
.float-user-info {
  @apply fixed z-[90] left-[120px] top-[150px];
  @apply w-[300px] rounded-[10px] bg-white shadow-lg select-none;
  @apply p-[20px] pb-0;
  &.small {
    @apply left-[50px] top-[100px];
  }
}

.op-btn {
  @apply flex justify-center items-center mb-[10px] leading-[40px] rounded-full text-white text-[14px] clickable;
  @apply bg-[var(--color-dark-blue)];
  @apply min-h-[40px];
}

:deep(.el-menu) {
  @apply border-none;
}
:deep(.el-menu-item.is-active) {
  @apply bg-primary text-white;
}
:deep(.el-sub-menu) {
  @apply transition-colors bg-white;
  transition-duration: 300ms;
}
:deep(.el-sub-menu.is-active, .el-sub-menu.is-active .el-menu) {
  @apply bg-primary-light text-white;
}
:deep(.el-sub-menu.is-active .el-sub-menu__title) {
  @apply text-primary;
}

:deep(.el-menu--collapse) {
  @apply w-full;
  .el-sub-menu .el-sub-menu__title,
  .el-menu-item {
    @apply rounded-l-[10px] pl-[15px] pr-[25px] text-primary;
  }
  .el-sub-menu.is-active {
    background-color: transparent;
  }
  .el-sub-menu.is-active .el-sub-menu__title,
  .el-menu-item.is-active {
    @apply bg-primary text-white;
  }
}
</style>
