import { watch, watchEffect } from 'vue'
import { useRouter } from 'vue-router'

import { useAuthStore } from '../stores'

/**
 * 跟踪登录态，并执行相应操作
 *
 * 在登录状态改变时
 * - `true`: 跳转到 /
 * - `false`: 跳转到 AUTH_ROUTE
 *
 * 当登录状态为 `true` 时，获取用户信息
 */
export function useTraceLoginStatus() {
  const router = useRouter()
  const authStore = useAuthStore()
  // 登录态变化跳转用 watch，不需要立即执行
  watch(
    () => authStore.isLogin,
    (isLogin) => (isLogin ? router.push('/') : router.push('/auth')),
  )
  // 登录态为 true 时获取用户信息
  watchEffect(() => {
    if (authStore.isLogin) authStore.fetchUserProfile()
  })
}
