<script setup lang="ts">
import { EncKeepAliveRouterView, useEventLock } from '@cphayim-enc/vue-element-plus'
import { useDateFormat, useNow } from '@vueuse/core'
import { storeToRefs } from 'pinia'
import { ref } from 'vue'

import { useAuthStore } from '@flyhigh-saas/auth'
import { ICONS } from '@flyhigh-saas/icons'
import { IconV } from '@flyhigh-saas/pc-base'

import { type UseRouterStore } from '../stores'
import MainSideBar from './MainSideBar.vue'
import PageTabs from './PageTabs.vue'

type Props = {
  /**
   * Layout 无法确定项目中使用到的 routes，因而需要在外部创建 useRouterStore 传递给 Layout
   */
  useRouterStore: UseRouterStore
}

const props = defineProps<Props>()

const collapse = ref(false)
const time = useDateFormat(useNow(), 'YYYY-MM-DD HH:mm:ss')

const authStore = useAuthStore()
const { logout } = authStore
const { user } = storeToRefs(authStore)
const handleLogout = useEventLock(logout)

const routerStore = props.useRouterStore()

// ------------------ simple message ------------------------------------
// const showFullMessageList = ref(false)
// const unReadMessageList = ref<MessageDTO[]>([])
// const unReadMsgTotal = ref('0')

// onMounted(() => loadMessage())

// const loadMessage = async () => {
//   const { rows, total } = await messageListAPI({
//     pageNum: 1,
//     pageSize: 6,
//     receiveStatus: ReceiveStatus.未读,
//   })
//   unReadMessageList.value = rows
//   unReadMsgTotal.value = total > 99 ? '99+' : String(total)
// }

// const markMessageRead = async () => {
//   await setMessageAllReadAPI()
//   unReadMessageList.value = []
//   unReadMsgTotal.value = '0'
// }

// watch(
//   () => showFullMessageList.value,
//   (val) => {
//     if (!val) loadMessage()
//   },
// )
</script>

<template>
  <div class="layout">
    <!-- 左侧主侧边栏 -->
    <MainSideBar :collapse="collapse" :useRouterStore="props.useRouterStore" />

    <!-- 右侧主体 -->
    <div class="flex-1 flex flex-col overflow-hidden">
      <div class="header-bar">
        <!-- 折叠面板开关 -->
        <IconV
          :icon="collapse ? ICONS.折叠_展开 : ICONS.折叠_收起"
          :size="26"
          color="#fff"
          @click="collapse = !collapse"
        />

        <div class="flex-1 flex justify-end items-center px-[10px] text-white">
          <div class="text-white text-[14px]">{{ time }}</div>
          <div class="w-[1px] h-[26px] mx-[20px] bg-white" />
          <!-- <el-popover placement="bottom-end" :width="300" trigger="click">
            <template #reference>
              <div class="leading-[10px] mr-[30px]">
                <el-badge :hidden="unReadMessageList.length === 0" :value="unReadMsgTotal">
                  <IconV :icon="ICONS.通知" />
                </el-badge>
              </div>
            </template>
            <div class="text-[#313131]">
              <span>消息中心</span>
              <span v-if="unReadMsgTotal != ''">（{{ unReadMsgTotal }}）</span>
            </div>
            <el-divider style="margin: 10px 0 14px 0; border-color: #f3f3fc" />

            <template v-if="unReadMessageList.length === 0">
              <el-empty :image-size="100" description="暂无未读消息" />
            </template>
            <template v-else>
              <ul class="p-0">
                <li
                  v-for="item in unReadMessageList"
                  :key="item.id"
                  class="flex justify-between text-[14px] my-[10px]"
                >
                  <span class="flex-1 truncate text-[#313131]">
                    <span class="custom-dot"></span>
                    <span>【{{ item.messageTypeDesc }}】{{ item.content }}</span>
                  </span>
                  <span class="w-[80px] text-[#A0A0A0]">{{ item.createTime.split(' ')[0] }} </span>
                </li>
              </ul>
            </template>
            <el-divider style="margin: 10px 0 10px 0; border-color: #f3f3fc" />
            <div class="flex justify-between text-[#313131]">
              <span class="cursor-pointer">
                <span @click="markMessageRead" v-show="unReadMessageList.length > 0"
                  >全部标记已读</span
                >
              </span>
              <span class="cursor-pointer">
                <span @click="showFullMessageList = true" class="align-middle">查看全部</span>
                <el-icon class="align-middle">
                  <ArrowRight />
                </el-icon>
              </span>
            </div>
          </el-popover> -->
          <IconV :icon="ICONS.退出" @click="handleLogout" mr color="#fff" />
        </div>
      </div>

      <!-- 页签 -->
      <PageTabs :useRouterStore="props.useRouterStore" />

      <div class="flex-1 overflow-auto">
        <!-- 二级路由视图 -->
        <EncKeepAliveRouterView v-if="user" :includes="routerStore.keepAlived" />
      </div>
    </div>

    <!-- <Message v-model:show="showFullMessageList" /> -->
  </div>
</template>

<style>
.enc-upload\[ep\] {
  @apply max-w-full;
}
</style>

<style scoped>
.layout {
  @apply overflow-hidden flex w-screen min-w-[1200px] h-screen;
  --layout-header-bar-height: 48px;
}

.header-bar {
  @apply flex-shrink-0 flex items-center h-[var(--layout-header-bar-height)] px-[10px] bg-primary;
}

.header-bar-btn {
  @apply mr-[15px];
}

.custom-dot {
  @apply inline-block w-[6px] h-[6px] mb-[2px] rounded-[50%] bg-[red];
}
</style>
