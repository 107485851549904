import { useLocalStorage } from '@vueuse/core'
import { defineStore } from 'pinia'
import { type Ref, computed, ref } from 'vue'

import { normalizeStorageKey } from '@flyhigh-saas/base'

import { type LoginReqDTO, type UserProfileDTO, authAPI, userProfileAPI } from '../apis/auth.api'

const ADMIN_PERMISSION = '*:*:*'

export const useAuthStore = defineStore('auth', () => {
  const token: Ref<string | null> = useLocalStorage<string | null>(
    normalizeStorageKey('token'),
    null,
  )
  const isLogin = computed(() => !!token.value)

  async function login(dto: LoginReqDTO) {
    const data = await authAPI(dto)
    token.value = data.token
  }
  async function logout() {
    token.value = null
  }

  // 用户信息、角色、权限
  const user = ref<UserProfileDTO | null>(null)
  const role = ref<string[]>([])
  const permissions = ref(new Set<string>())
  // 是否是调度员（工单系统）
  // TODO
  const whetherDispatcherUser = ref<boolean>()

  async function fetchUserProfile() {
    const data = await userProfileAPI()
    user.value = data.user
    role.value = data.roles
    whetherDispatcherUser.value = data.whetherDispatcherUser
    permissions.value = new Set(data.permissions)
  }

  function hasPermission(permission: string) {
    if (permissions.value.has(ADMIN_PERMISSION)) return true
    return permissions.value.has(permission)
  }

  return {
    token,
    isLogin,
    login,
    logout,
    user,
    role,
    permissions,
    hasPermission,
    fetchUserProfile,
    whetherDispatcherUser,
  }
})
