import type { RouteComponent, RouteRecordRaw } from 'vue-router'

import { SaaSPlatform } from '@flyhigh-saas/base'

export enum SystemRouteEnum {
  SystemOrgsPage = 'SystemOrgsPage',
  SystemUsersPage = 'SystemUsersPage',
  SystemRolesPage = 'SystemRolesPage',
  SystemMenusPage = 'SystemMenusPage',
  SystemDictsPage = 'SystemDictsPage',
  SystemLogsPage = 'SystemLogsPage',
}

type CMap = Record<SystemRouteEnum, RouteComponent | (() => Promise<RouteComponent>)>

const DEFAULT_ROUTE_COMPONENTS: CMap = {
  SystemOrgsPage: () => import('./orgs/SystemOrgsPage.vue'),
  SystemUsersPage: () => import('./users/SystemUsersPage.vue'),
  SystemRolesPage: () => import('./roles/SystemRolesPage.vue'),
  SystemMenusPage: () => import('./menus/SystemMenusPage.vue'),
  SystemDictsPage: () => import('./dicts/SystemDictsPage.vue'),
  SystemLogsPage: () => import('./logs/SystemLogsPage.vue'),
}

export function createSystemRoutes(componentMap: Partial<CMap> = {}): RouteRecordRaw[] {
  const cMap = { ...DEFAULT_ROUTE_COMPONENTS, ...componentMap }
  return [
    {
      path: '/system/orgs',
      name: SystemRouteEnum.SystemOrgsPage,
      component: cMap.SystemOrgsPage,
      meta: {
        platform: SaaSPlatform.系统管理,
        auth: true,
        title: '组织机构',
        keepAlive: true,
        menu: true,
      },
    },
    {
      path: '/system/users',
      name: SystemRouteEnum.SystemUsersPage,
      component: cMap.SystemUsersPage,
      meta: {
        platform: SaaSPlatform.系统管理,
        auth: true,
        title: '用户管理',
        keepAlive: true,
        menu: true,
      },
    },
    {
      path: '/system/roles',
      name: SystemRouteEnum.SystemRolesPage,
      component: cMap.SystemRolesPage,
      meta: {
        platform: SaaSPlatform.系统管理,
        auth: true,
        title: '角色管理',
        keepAlive: true,
        menu: true,
      },
    },
    {
      path: '/system/menus',
      name: SystemRouteEnum.SystemMenusPage,
      component: cMap.SystemMenusPage,
      meta: {
        platform: SaaSPlatform.系统管理,
        auth: true,
        title: '菜单管理',
        keepAlive: true,
        menu: true,
      },
    },
    {
      path: '/system/dicts',
      name: SystemRouteEnum.SystemDictsPage,
      component: cMap.SystemDictsPage,
      meta: {
        platform: SaaSPlatform.系统管理,
        auth: true,
        title: '字典管理',
        keepAlive: true,
        menu: true,
      },
    },
    {
      path: '/system/logs',
      name: SystemRouteEnum.SystemLogsPage,
      component: cMap.SystemLogsPage,
      meta: {
        platform: SaaSPlatform.系统管理,
        auth: true,
        title: '操作日志',
        keepAlive: true,
        menu: true,
      },
    },
  ]
}

export const systemRoutes = createSystemRoutes()
