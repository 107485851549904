import type { NavigationHookAfter } from 'vue-router'

import { type UseRouterStore } from '../stores'

/**
 * 将页面组合添加到缓存列表
 * @type 后置守卫
 */
export function createKeepAliveGuard(useRouterStore: UseRouterStore) {
  return function keepAliveGuard(to, _from): void {
    const routerStore = useRouterStore()
    if (to.meta.keepAlive) {
      // 将 to 添加到缓存列表，注意：路由 name 需要和组件 name 相等
      routerStore.addKeepAlive({
        routeName: to.name as string,
        routePath: to.path,
        title: to.meta.title,
        componentName: to.name as string, // 注意：路由 name 需要和组件 name 相等
      })
    }
  } as NavigationHookAfter
}
