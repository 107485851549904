/**
 * 响应 code 枚举
 *
 * 业务 code 可以通过在业务模块扩展该枚举类型来定义
 */
export enum ResponseCode {
  // 通用 code
  OK = 200, // 成功
  BAD_PARAMS = 400, // 参数错误
  UNAUTHORIZED = 401, // token 失效
  FORBIDDEN = 403, // 拒绝访问（没有权限）
  NOT_FOUND = 404, // 没有该资源
  SERVER_ERROR = 500, // 服务端错误
  MAINTENANCE = 503, // 系统维护中
  // 业务 code
  // ...
}
