import {
  type FetchinResponseBody,
  type ID,
  type RawResponseBody,
  b64Encode,
} from '@flyhigh-saas/base'

import { strictFetch } from '../http/strict-fetch'

export type LoginReqDTO = {
  username: string
  password: string
  code?: string
  uuid?: string
  platform?: string
}
type LoginResDTO = {
  token: string
}

// 普通登录
export async function loginAPI(data: LoginReqDTO) {
  const { data: resBody } = await strictFetch.post<FetchinResponseBody<LoginResDTO>>(
    '/system/login',
    data,
  )
  return resBody.data
}

// 认证登录（带编码）
export async function authAPI(data: LoginReqDTO) {
  const { data: resBody } = await strictFetch.post<RawResponseBody<LoginResDTO>>('/system/auth', {
    payload: getAuthPayload(data),
    code: data.code,
    uuid: data.uuid,
    platform: data.platform,
  })
  return resBody.data
}

function getAuthPayload(data: LoginReqDTO) {
  const payload = `${b64Encode(data.username, true)}.${b64Encode(data.password, true)}.${b64Encode(
    Date.now().toString(),
    true,
  )}`
  return payload.split('').reverse().join('')
}

type UserInfoDTO = {
  user: UserProfileDTO
  roles: string[]
  permissions: string[]
  whetherDispatcherUser: boolean
}
export type UserProfileDTO = {
  userId: ID // 用户ID
  userName: string // 用户名
  nickName: string // 昵称
  userType: string // 用户类型
  email: string // 邮箱
  phonenumber: string // 手机号
  postName: string // 岗位
  sex: string // 性别
  avatar: string // 头像
  remark: string // 备注
  admin: boolean // 是否管理员（仅超管）
  roles: UserProfileRoleDTO[] // 角色，存在多个
  dept: UserProfileDeptDTO // 部门
}
export type UserProfileRoleDTO = {
  roleId: ID // 角色ID
  roleKey: string // 角色 key
  roleName: string // 角色名
}
export type UserProfileDeptDTO = {
  deptId: ID // 部门ID
  deptName: string // 部门名
}

// 获取用户信息
export async function userProfileAPI() {
  const { data: resBody } = await strictFetch.get<RawResponseBody<UserInfoDTO>>('/system/getInfo')
  return resBody.data
}

export enum MenuType {
  目录 = 'M', // 目录
  菜单 = 'C', // 菜单
  按钮 = 'F', // 按钮
}

export type UserRouteDTO = {
  menuType: MenuType // 菜单类型，这里只可能为"目录"或"菜单"
  path: string // 路由标识，存的是 route.name
  icon: string // 菜单图标
  children?: UserRouteDTO[] // 子菜单
  meta: {
    title: string // 菜单标题
    icon: string // 菜单图标
  }
}

// 获取用户路由/菜单
export async function userRoutesAPI() {
  const { data: resBody } = await strictFetch.get<RawResponseBody<UserRouteDTO[]>>(
    '/system/getRouters',
  )
  return resBody.data
}

export type CaptchaImageDTO = {
  uuid: string
  img: string
}
// 生成验证码
export async function getCaptchaImageAPI() {
  const { data: resBody } = await strictFetch.get<RawResponseBody<CaptchaImageDTO>>(
    '/system/captchaImage',
  )
  return resBody.data
}
