import dayjs from 'dayjs'
import { decode, encode } from 'js-base64'

export * from './download'

/**
 * 格式化存储用 key
 */
export const normalizeStorageKey = (key: string, pkgName = '@flyhigh-saas') => `${pkgName}:${key}`

/**
 * 日期格式化
 */
export function datetimeFormat(date: Date | string, format = 'YYYY-MM-DD HH:mm:ss'): string {
  return dayjs(date).format(format)
}

/**
 * 字符串截取
 */
export function cropStr(str: string, length: number) {
  return str.length > length ? `${str.slice(0, length)}...` : str
}

/**
 * base64 编码
 */
export function b64Encode(str: string, removeSuffix = false) {
  const b64Str = encode(str)
  return removeSuffix ? b64Str.replaceAll('=', '') : b64Str
}

/**
 * base64 解码
 */
export function b64Decode(str: string) {
  return decode(str)
}

export function deepClone<T>(obj: T): T {
  return JSON.parse(JSON.stringify(obj))
}
