/*
 * @Author: Cphayim
 * @Date: 2022-12-15 00:12:45
 * @Description: 路由状态 store
 */
import { defineStore } from 'pinia'
import { computed, ref, watch } from 'vue'
import { type RouteRecordRaw, useRouter } from 'vue-router'

import { MenuType, type UserRouteDTO, useAuthStore, userRoutesAPI } from '@flyhigh-saas/auth'
import { ICONS } from '@flyhigh-saas/icons'

import { useKeepAlivedRouter } from '../hooks'

export type RouteMenu = {
  fullPath: string
  title: string
  icon?: any
  children?: RouteMenu[]
}

export type CreateRouterStoreOptions = {
  /**
   * 在 layout 菜单中的完整路由数组，将基于此过滤出菜单
   */
  routes: RouteRecordRaw[]
}

/**
 * 需要调用 createRouterStore()，返回 useRouterStore
 */
export const createRouterStore = ({ routes }: CreateRouterStoreOptions) => {
  return defineStore('router', () => {
    const router = useRouter()
    const authStore = useAuthStore()
    const _routesLoaded = ref(false)
    const routesLoaded = computed(() => _routesLoaded.value)

    // 当退出登录时，应该将 _routesLoaded 重置为 false，以便在路由守卫中重新加载路由菜单
    watch(
      () => authStore.isLogin,
      (isLogin) => {
        if (!isLogin) {
          _routesLoaded.value = false
        }
      },
    )

    const dynamicRoutesMap = routes.reduce((map, route) => {
      map[route.name as string] = route
      return map
    }, {} as Record<string, RouteRecordRaw>)

    // 默认包含首页
    const menus = ref<RouteMenu[]>([])

    const fetchRoutes = async () => {
      if (_routesLoaded.value) return
      const tree = await userRoutesAPI()

      menus.value = [{ fullPath: '/dashboard', title: '首页', icon: ICONS.菜单_首页 }]

      function filter(tree: UserRouteDTO[], menus: RouteMenu[]) {
        if (!tree || !tree.length) return

        tree.forEach((node) => {
          if (node.menuType === MenuType.菜单) {
            const route = dynamicRoutesMap[node.path] // node.path 存的是 route.name
            route && router.addRoute('Layout', route)
          }

          const menu: RouteMenu = {
            fullPath: dynamicRoutesMap[node.path]?.path ?? '#',
            title: node.meta.title,
            icon: node.meta.icon !== '#' && node.meta.icon,
          }

          menus.push(menu)

          if (node.menuType === MenuType.目录 && node.children) {
            menu.children = []
            filter(node.children, menu.children)
          }
        })
      }

      filter(tree, menus.value)

      _routesLoaded.value = true
    }

    return {
      ...useKeepAlivedRouter(),
      // --------------------------------------------------
      routesLoaded,
      menus,
      fetchRoutes,
    }
  })
}

export type UseRouterStore = ReturnType<typeof createRouterStore>
