import type { RouteRecordRaw } from 'vue-router'

import { authRoutes } from '@flyhigh-saas/pc-auth'
import { baseRoutes } from '@flyhigh-saas/pc-base'
import { contractRoutes } from '@flyhigh-saas/pc-contract'
import { dashboardRoutes } from '@flyhigh-saas/pc-dashboard'
import { Layout, type UseRouterStore } from '@flyhigh-saas/pc-layout'
import { operateRoutes } from '@flyhigh-saas/pc-operate'
import { projectRoutes } from '@flyhigh-saas/pc-project'
import { projectArchiveRoutes } from '@flyhigh-saas/pc-project-archive'
import { systemRoutes } from '@flyhigh-saas/pc-system'
import { workorderRoutes } from '@flyhigh-saas/pc-workorder'

import { materialRoutes } from '@/views'

export const dynamicRoutes = [
  ...materialRoutes,
  ...contractRoutes, // 合同管理
  ...systemRoutes, // 系统管理
  ...projectRoutes, // 项目管理（基础）
  ...projectArchiveRoutes, // 项目管理（主档）
  ...operateRoutes, // 运营管理
]

export function createGlobalRoutes(useRouterStore: UseRouterStore) {
  const layoutRoute: RouteRecordRaw = {
    path: '/',
    name: 'Layout',
    component: Layout,
    props: () => ({
      // Layout 组件依赖 useRouterStore
      useRouterStore,
    }),
    meta: {
      auth: true,
      title: '首页',
    },
    redirect: dashboardRoutes[0].path,
    // 首页 dashboard 一定存在，其它路由通过接口返回的菜单解析添加到 Layout 上
    children: [...(dashboardRoutes as any)],
  }
  return [
    ...baseRoutes, // 基本路由（404 页面等，兜底）
    ...authRoutes, // 授权
    ...workorderRoutes,
    layoutRoute,
  ] as RouteRecordRaw[]
}
