<script setup lang="ts">
import { ref, watch } from 'vue'

type DialogProps = {
  /**
   * 标题
   */
  title?: string
  /**
   * 宽度
   */
  width: string
  /**
   * v-model 是否显示 dialog
   */
  modelValue: boolean
  /**
   * 是否显示底部按钮组
   */
  buttons?: boolean
  /**
   * 每次显示时重新创建 slot 中的内容
   */
  rebuild?: boolean
  /**
   *
   */
  confirmText?: string
  /**
   *
   */
  cancelText?: string
}
const props = defineProps<DialogProps>()
const emit = defineEmits<{
  (event: 'open'): void
  (event: 'opened'): void
  (event: 'close'): void
  (event: 'closed'): void
  (event: 'confirm'): void
  (event: 'cancel'): void
  (event: 'update:modelValue', value: boolean): void
}>()

const modelValue = ref(props.modelValue)

watch(
  () => props.modelValue,
  (val) => {
    modelValue.value = val
  },
)

const getKey = () => Math.random().toString().slice(2)
const key = ref<string>(getKey())

watch(
  () => modelValue.value,
  (val) => {
    emit('update:modelValue', val)
    props.rebuild && !val && (key.value = getKey())
  },
)
</script>

<template>
  <div :class="['cust_dialog', { cust_dialog_close_header: props.title === undefined }]">
    <el-dialog
      v-model="modelValue"
      :width="props.width"
      @open="emit('open')"
      @opened="emit('opened')"
      @close="emit('close')"
      @closed="emit('closed')"
    >
      <template v-if="props.title" #header>
        <span class="el-dialog__title"> {{ props.title }}</span>
      </template>

      <slot :key="key" />

      <template v-if="buttons" #footer>
        <div class="flex justify-center items-center">
          <el-button
            @click="
              () => {
                emit('cancel')
                modelValue = false
              }
            "
            class="btn cancel"
          >
            {{ props.cancelText ?? '取消' }}
          </el-button>
          <el-button @click="emit('confirm')" class="btn confirm">
            {{ props.confirmText ?? '确定' }}
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<style>
.cust_dialog_close_header .el-dialog__header {
  height: 0 !important;
  padding: 0 !important;
}

.cust_dialog .el-dialog {
  @apply rounded-[10px];
}

.cust_dialog .el-dialog__header {
  @apply h-[48px] pt-[12px] m-0 text-center bg-[#E1EBFF] rounded-t-[10px];
}

.cust_dialog .el-dialog__headerbtn {
  @apply top-0;
}

.cust_dialog .el-dialog__title {
  @apply text-[16px] text-[var(--color-light-blue)] font-normal;
}

.cust_dialog .el-dialog__close {
  @apply text-[var(--color-light-blue)];
}

.cust_dialog .btn {
  @apply w-[94px] h-[32px];
}

.cust_dialog .confirm {
  @apply bg-[var(--color-light-blue)] text-white;
}

.cust_dialog .cancel {
  @apply border-[#7D7D7D] text-[#313131];
}
</style>
