import type { RouteRecordRaw } from 'vue-router'

import { SaaSPlatform } from '@flyhigh-saas/base'

export const operateRoutes = [
  // 原资产管理
  {
    path: '/assets/unit',
    name: 'AssetsUnitPage',
    component: () => import('./assets/unit/assetsUnitPage.vue'),
    meta: {
      auth: true,
      title: '资产单元管理',
      keepAlive: true,
      menu: true,
      platform: SaaSPlatform.运营管理,
    },
  },
  {
    path: '/assets/type',
    name: 'AssetsTypePage',
    component: () => import('./assets/type/assetsTypePage.vue'),
    meta: {
      auth: true,
      title: '资产类型管理',
      keepAlive: true,
      menu: true,
      platform: SaaSPlatform.运营管理,
    },
  },
  // 原营销管理
  {
    path: '/marketing/lease-info',
    name: 'MarketingLeaseInfoPage',
    component: () => import('./marketing/lease-info/marketingLeaseInfoPage.vue'),
    meta: {
      auth: true,
      title: '租赁信息管理',
      keepAlive: true,
      menu: true,
      platform: SaaSPlatform.运营管理,
    },
  },
  {
    path: '/marketing/sell-info',
    name: 'MarketingSellInfoPage',
    component: () => import('./marketing/sell-info/marketingSellInfoPage.vue'),
    meta: {
      auth: true,
      title: '售卖信息管理',
      keepAlive: true,
      menu: true,
      platform: SaaSPlatform.运营管理,
    },
  },
  {
    path: '/marketing/tenant',
    name: 'MarketingTenantPage',
    component: () => import('./marketing/tenant/marketingTenantPage.vue'),
    meta: {
      auth: true,
      title: '租客管理',
      keepAlive: true,
      menu: true,
      platform: SaaSPlatform.运营管理,
    },
  },
  // 原物管管理
  {
    path: '/tenements/attendance',
    name: 'TenementsAttendancePage',
    component: () => import('./tenements/attendance/TenementsAttendancePage.vue'),
    meta: {
      auth: true,
      title: '打卡考勤',
      keepAlive: true,
      menu: true,
      platform: SaaSPlatform.运营管理,
    },
  },
  {
    path: '/tenements/patrol',
    name: 'TenementsPatrolPage',
    component: () => import('./tenements/patrol/TenementsPatrolPage.vue'),
    meta: {
      auth: true,
      title: '日常巡查',
      keepAlive: true,
      menu: true,
      platform: SaaSPlatform.运营管理,
    },
  },
  {
    path: '/tenements/expend',
    name: 'TenementsExpendPage',
    component: () => import('./tenements/expend/TenementsExpendPage.vue'),
    meta: {
      auth: true,
      title: '支出管理',
      keepAlive: true,
      menu: true,
      platform: SaaSPlatform.运营管理,
    },
  },
  {
    path: '/tenements/meter',
    name: 'TenementsMeterPage',
    component: () => import('./tenements/meter/TenementsMeterPage.vue'),
    meta: {
      auth: true,
      title: '抄表管理',
      keepAlive: true,
      menu: true,
      platform: SaaSPlatform.运营管理,
    },
  },
  {
    path: '/tenements/repair',
    name: 'TenementsRepairPage',
    component: () => import('./tenements/repair/TenementsRepairPage.vue'),
    meta: {
      auth: true,
      title: '报修申请',
      keepAlive: true,
      menu: true,
      platform: SaaSPlatform.运营管理,
    },
  },
  {
    path: '/tenements/surrender',
    name: 'TenementsSurrenderPage',
    component: () => import('./tenements/surrender/TenementsSurrenderPage.vue'),
    meta: {
      auth: true,
      title: '退租信息',
      keepAlive: true,
      menu: true,
      platform: SaaSPlatform.运营管理,
    },
  },
] as RouteRecordRaw[]
