import type { RouteComponent, RouteRecordRaw } from 'vue-router'

export enum DashboardRouteEnum {
  Dashboard = 'Dashboard',
}

type CMap = Record<DashboardRouteEnum, RouteComponent | (() => Promise<RouteComponent>)>

export const DEFAULT_ROUTE_COMPONENTS: CMap = {
  Dashboard: () => import('./DashboardPage.vue'),
}

export function createDashboardRoutes(componentMap: Partial<CMap> = {}): RouteRecordRaw[] {
  const cMap = { ...DEFAULT_ROUTE_COMPONENTS, ...componentMap }
  return [
    {
      path: '/dashboard',
      name: DashboardRouteEnum.Dashboard,
      component: cMap.Dashboard,
      meta: {
        auth: true,
        title: '首页',
      },
    },
  ]
}

export const dashboardRoutes = createDashboardRoutes()
