import type { NavigationGuardWithThis } from 'vue-router'

import { useAuthStore } from '@flyhigh-saas/auth'

import type { UseRouterStore } from '../stores'

/**
 * 动态路由加载守卫，登录后获取菜单以及添加对应的路由
 *
 * - 需要依赖前置守卫
 *   - @flyhigh-saas/auth -> authGuard
 *
 * @type 前置守卫
 */
export function createRouteLoadGuard(useRouterStore: UseRouterStore) {
  return async function routeLoadGuard(to, _from, next) {
    const authStore = useAuthStore()
    const routerStore = useRouterStore()
    // 仅登录态为 true 才处理，因为无需要登录访问的页面一定在静态路由里注册过了
    if (!authStore.isLogin) {
      return next()
    }

    if (routerStore.routesLoaded) {
      next()
    } else {
      await routerStore.fetchRoutes()
      // 这里需要发起一次重定向，因为路由可能是刚添加的
      next(to.path)
    }
  } as NavigationGuardWithThis<undefined>
}
