import { strictFetch } from '@flyhigh-saas/auth'
import {
  type CUTimeDTO,
  type ID,
  type ListQueryParams,
  type ListResponseBody,
  type ResponseBody,
  downloadBlobFile,
} from '@flyhigh-saas/base'
import { type UploadFileResult } from '@flyhigh-saas/system'

/**
 * 项目类型
 */
export enum ProjectType {
  建设类 = '1',
  资产类 = '2',
}
export function getProjectTypeLabel(type: ProjectType) {
  const map: Record<ProjectType, string> = {
    [ProjectType.建设类]: '建设类',
    [ProjectType.资产类]: '资产类',
  }
  return map[type]
}

export type ProjectQueryParams = ListQueryParams & {
  projectType?: string
  projectName?: string
  projectCode?: string
}

export type ProjectDTO = CUTimeDTO & {
  id: ID
  projectName: string // 项目名称
  projectCode: string // 项目编码
  projectType: ProjectType // 项目类型
  projectBaseInfo: string // 项目基本情况
  projectAddress: string // 项目地址
  projectCategory: string // 项目类别（字典值）
  projectCategoryName: string // 项目类别
  projectStatus: string // 项目状态（字典）
  freeMaintenanceDeadline: string // 免费维护截止时间
  projectStatusName: string // 项目状态
  createBy: string // 创建人
  createUserId: ID // 创建人id
  deptId: ID // 所属部门id
  deptName: string // 所属部门
  companyId: ID // 所属公司id
  companyName: string // 所属公司
  remark: string // 备注
  maintenanceSwitch: 0 | 1 // 报修许可
  maintenanceSwitchBtnShow: boolean

  // 建设负责人
  projectBuildLeader: string // 建设负责人
  projectBuildLeaderDuty: string // 建设负责人职务
  projectBuildLeaderId: ID // 建设负责人id
  projectBuildLeaderPhone: string // 建设负责人电话
  projectBuildLeaderPic: string // 建设负责人图片

  // 资产负责人
  projectAssetLeader: string // 资产运营负责人
  projectAssetLeaderDuty: string // 资产运营负责人职务
  projectAssetLeaderId: ID // 资产运营负责人id
  projectAssetLeaderPhone: string // 资产运营负责人电话
  projectAssetLeaderPic: string // 资产运营负责人图片

  projectBuildPic: string // 项目建设图片
  projectBuildPicFiles: UploadFileResult[]
  projectQrCodePics: UploadFileResult[]
  projectAssetPic: string // 项目资产图片
  projectAssetPicFiles: UploadFileResult[]
  projectMaintainPic: string // 项目维护图片
  projectMaintainPicFiles: UploadFileResult[]
}

// 获取项目列表
export async function projectListAPI(params: ProjectQueryParams) {
  const { data: resBody } = await strictFetch.get<ListResponseBody<ProjectDTO>>(
    '/project/projectInfo/list',
    {
      params,
    },
  )
  return resBody.data
}

export type SimpleProjectDTO = Pick<
  ProjectDTO,
  'id' | 'projectName' | 'projectCode' | 'projectType'
>

// 获取简易项目列表（下拉选择用）
export async function simpleProjectListAPI(params?: Pick<ProjectQueryParams, 'projectType'>) {
  const { data: resBody } = await strictFetch.get<ResponseBody<SimpleProjectDTO[]>>(
    '/project/projectInfo/simpleProjectlist',
    {
      params,
    },
  )
  return resBody.data
}

// 获取项目信息详细信息
export async function projectDetailAPI(id: ID) {
  const { data: resBody } = await strictFetch.get<ResponseBody<ProjectDTO>>(
    `/project/projectInfo/${id}`,
  )
  return resBody.data
}

// 新增项目信息
export async function createProjectAPI(data: Partial<ProjectDTO>) {
  const { data: resBody } = await strictFetch.post<ResponseBody>(`/project/projectInfo/add`, data)
  return resBody.data
}

// 更新项目信息
export async function updateProjectAPI(data: Partial<ProjectDTO>) {
  const { data: resBody } = await strictFetch.post<ResponseBody>(`/project/projectInfo/edit`, data)
  return resBody.data
}

// 删除项目信息
export async function deleteProjectAPI(ids: ID[]) {
  const { data: resBody } = await strictFetch.delete<ResponseBody>(
    `/project/projectInfo/${ids.join(',')}`,
  )
  return resBody.data
}

// 导出项目数据
export async function exportProjectAPI(data: ProjectQueryParams) {
  const { data: resBody } = await strictFetch.post<Blob>('/project/projectInfo/export', data, {
    responseType: 'blob',
  })
  downloadBlobFile(resBody, `项目信息_${Date.now()}.xlsx`)
}

// 获取项目列表-基本数据
export async function simpleProjectlistAPI(projectType: string) {
  const { data: resBody } = await strictFetch.get<ResponseBody<ProjectDTO[]>>(
    `/project/projectInfo/simpleProjectlist`,
    {
      params: {
        projectType,
      },
    },
  )
  return resBody.data
}

// 查询项目列表
export async function getAllProjectListByPCProjectPackAPI() {
  const { data: resBody } = await strictFetch.get<ResponseBody<any[]>>(
    '/project/projectInfo/getAllList',
  )
  return resBody.data
}

// 报修许可
export async function updateMaintenanceSwitchAPI(data: { id: ID; maintenanceSwitch: 0 | 1 }) {
  const { data: resBody } = await strictFetch.post<ResponseBody<any>>(
    '/project/projectInfo/updateMaintenanceSwitch',
    data,
  )
  return resBody.data
}
