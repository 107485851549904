/*
 * @Author: Cphayim
 * @Date: 2023-07-04 16:43:44
 * @Description:
 */
import cloneDeep from 'lodash.clonedeep'
import { type PiniaPlugin } from 'pinia'

/**
 * $reset 补丁
 *
 * pinia setup 语法不支持 $reset 重置 store 状态的补丁插件
 */
export const piniaResetPatch: PiniaPlugin = ({ store }) => {
  // record initState when store created
  const initialState = cloneDeep(store.$state)
  store.$reset = () => {
    store.$patch(cloneDeep(initialState))
  }
}
