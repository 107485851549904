import { computed, ref } from 'vue'

export type KeepAliveRouteInfo = {
  componentName: string // 组件名称，用于 keepAlived
  title: string // 路由标题
  routeName: string // 路由名称
  routePath: string // 路由路径
}

export function useKeepAlivedRouter() {
  const keepAliveMap = ref<Map<KeepAliveRouteInfo['componentName'], KeepAliveRouteInfo>>(new Map())

  // keepAlive 的组件名列表（路由名称需要和组件名称相等）
  const keepAlived = computed(() => Array.from(keepAliveMap.value.keys()))
  // keepAlive 对应的路由信息，用于 Tabs 页签
  const keepAlivedInfos = computed(() => Array.from(keepAliveMap.value.values()))

  const addKeepAlive = (info: KeepAliveRouteInfo) => {
    keepAliveMap.value.set(info.componentName, info)
  }
  const rmKeepAlive = (info: KeepAliveRouteInfo) => {
    keepAliveMap.value.delete(info.componentName)
  }

  return {
    keepAliveMap,
    keepAlived,
    keepAlivedInfos,
    addKeepAlive,
    rmKeepAlive,
  }
}
