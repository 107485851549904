import type { NavigationGuardWithThis } from 'vue-router'

import { AUTH_ROUTE } from '@flyhigh-saas/base'

import { useAuthStore } from '../stores'

/**
 * 登录态守卫
 * 确保已登录才能访问 `meta.auth: true` 的路由
 * @type 前置守卫
 */
export const authGuard: NavigationGuardWithThis<undefined> = (to, _from, next) => {
  const authStore = useAuthStore()

  // 如果页面需要登录且未登录，跳转到登录页
  if (to.meta.auth && !authStore.isLogin) {
    next(AUTH_ROUTE)
  } else {
    next()
  }
}
